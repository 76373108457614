<template>
  <div>

    <b-card-code>
      <session-Details />
    </b-card-code>
    <b-modal
              ref="my-modal"
              centered
              cancel-title="No"
              cancel-variant="outline-secondary"
              title-tag="div"
            >
              <validation-observer ref="phonesimple">
                <b-form>
                  <b-row>
                    <b-col
                      md="6"
                      xl="6"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="contacttype"
                        rules="required"
                      >
                        <b-form-group
                          label-for="servicename"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label> اسم الخدمة </label>
                          <v-select
                            v-model="newService.main_service_id"
                            label="name"
                            :reduce="(val) => val.id"
                            :options="optionService"
                            @input="getSubSevice(newService.main_service_id)"
                          />
                        </b-form-group>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-col>
                    <b-col
                      md="6"
                      xl="6"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="documentType"
                        rules="required"
                      >
                        <b-form-group
                          label-for="cardNumber"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label> الخدمة الفرعية </label>
                          <v-select
                            v-model="newService.sub_service_id"
                            label="name"
                            :reduce="(val) => val.id"
                            :options="optionSubServices"
                          />
                        </b-form-group>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-col>

                    <b-col
                      md="6"
                      xl="6"
                    >
                      <b-form-group label-for="delimiter">
                        <label>الملاحظات</label>
                        <b-form-input
                          id="textarea-default"
                          v-model="newService.notes"
                          placeholder="الملاحظات"
                          rows="1"
                        />
                      </b-form-group>
                    </b-col>

                  </b-row>
                </b-form>
              </validation-observer>
              <template #modal-footer>
               
                <b-button
                 
                  variant="outline-primary"
                  @click="addnewService"
                >
                  <span class="align-middle"> تحويل  </span>
                </b-button>
              </template>
            </b-modal>
            <b-modal
              ref="my-modal-attend"
              centered
              cancel-title="No"
              cancel-variant="outline-secondary"
              title-tag="div"
            >
            
<div v-if="type === 'App\\Models\\Person'">
  <b-row >
            <b-col md="6" xl="3">
              <b-form-group label-for="number">
                <label> الاسم</label>
                <h5 class="text-capitalize mb-75">
                  {{ data.first_name }}
                </h5>
              </b-form-group>
            </b-col>

            <b-col md="6" xl="3">
              <b-form-group label-for="number">
                <label> الاب</label>
                <h5 class="text-capitalize mb-75">
                  {{ data.father_name }}
                </h5>
              </b-form-group>
            </b-col>

            <b-col md="6" xl="3">
              <b-form-group label-for="number">
                <label> الام</label>
                <h5 class="text-capitalize mb-75">
                  {{ data.mother_name }}
                </h5>
              </b-form-group>
            </b-col>

            <b-col md="6" xl="3">
              <b-form-group label-for="number">
                <label> النسبة</label>
                <h5 class="text-capitalize mb-75">
                  {{ data.last_name }}
                </h5>
              </b-form-group>
            </b-col>

            <b-col md="6" xl="3" v-if="data.gender">
              <b-form-group>
                <label> الجنس</label>
                <h5 class="text-capitalize mb-75">
                  {{ data.gender.name }}
                </h5>
              </b-form-group>
            </b-col>
            <b-col md="6" xl="3" v-if="data.family_status">
              <b-form-group>
                <label> طبيعة العائلة </label>
                <h5 class="text-capitalize mb-75">
                  {{ data.family_status.name }}
                </h5>
              </b-form-group>
            </b-col>

        
         
            <b-col md="6" xl="3" v-if="data.martial_status">
              <b-form-group label-for="martial_status">
                <label> الحالة الاجتماعية</label>

                <h5 class="text-capitalize mb-75">
                  {{ data.martial_status }}
                </h5>
              </b-form-group>
            </b-col>


            <b-col md="6" xl="3" v-if="data.educational_attainment">
              <b-form-group label-for="educational_attainment">
                <label> التحصيل العلمي </label>

                <h5 class="text-capitalize mb-75">
                  {{ data.educational_attainment }}
                </h5>
              </b-form-group>
            </b-col>
            <b-col md="6" xl="3" v-if="data.phone">
              <b-form-group label-for="number">
                <label> رقم الهاتف  </label>
                <h5 class="text-capitalize mb-75">
                  {{ data.phone }}
                </h5>
              </b-form-group>
            </b-col>
           

            </b-row>
          <b-row>
            <b-col md="6" xl="4">
              <b-form-group label-for="birth_date">
                <label> تاريخ الميلاد</label>
                <h5 class="text-capitalize mb-75">
                  {{ data.birth_date }}
                </h5>
              </b-form-group>
            </b-col>
            <b-col md="6" xl="4">
              <b-form-group label-for="birth_place">
                <label>  مكان الولادة</label>
                <h5 class="text-capitalize mb-75">
                  {{ data.birth_place }}
                </h5>
              </b-form-group>
            </b-col>

          </b-row>


</div>
<div v-else-if="type === 'App\\Models\\User'">

  <b-row >
            <b-col md="6" xl="3">
              <b-form-group label-for="number">
                <label> الاسم</label>
                <h5 class="text-capitalize mb-75">
                  {{ data.first_name }}
                </h5>
              </b-form-group>
            </b-col>

      

            <b-col md="6" xl="3">
              <b-form-group label-for="number">
                <label> النسبة</label>
                <h5 class="text-capitalize mb-75">
                  {{ data.last_name }}
                </h5>
              </b-form-group>
            </b-col>

            <b-col md="6" xl="3" v-if="data.gender">
              <b-form-group>
                <label> الجنس</label>
                <h5 class="text-capitalize mb-75">
                  {{ data.gender == 1 ? "ذكر" : "أنثى" }}
                </h5>
              </b-form-group>
            </b-col>


            <b-col md="6" xl="3">
              <b-form-group label-for="birth_date">
                <label> تاريخ الولادة</label>
                <h5 class="text-capitalize mb-75">
                  {{ data.birth_date }}
                </h5>
              </b-form-group>
            </b-col>

            <b-col md="6" xl="3" v-if="data.phone">
              <b-form-group label-for="number">
                <label> رقم الهاتف  </label>
                <h5 class="text-capitalize mb-75">
                  {{ data.phone }}
                </h5>
              </b-form-group>
            </b-col>
            

            </b-row>
    

</div>
<div v-else-if="type === 'App\\Models\\Volunteer'">

  <b-row >
            <b-col md="6" xl="3">
              <b-form-group label-for="number">
                <label> الاسم</label>
                <h5 class="text-capitalize mb-75">
                  {{ data.name }}
                </h5>
              </b-form-group>
            </b-col>


            <b-col md="6" xl="3" v-if="data.address">
              <b-form-group label-for="address">
                <label> العنوان </label>
                <h5 class="text-capitalize mb-75">
                  {{ data.address }}
                </h5>
              </b-form-group>
            </b-col>
           


            <b-col md="6" xl="3" v-if="data.phone">
              <b-form-group label-for="number">
                <label> رقم الهاتف  </label>
                <h5 class="text-capitalize mb-75">
                  {{ data.number }}
                </h5>
              </b-form-group>
            </b-col>
    

            </b-row>
         
</div>
<div v-else>

  <b-row >
            <b-col md="6" xl="3">
              <b-form-group label-for="number">
                <label> الاسم</label>
                <h5 class="text-capitalize mb-75">
                  {{ data.first_name }}
                </h5>
              </b-form-group>
            </b-col>

            <b-col md="6" xl="3">
              <b-form-group label-for="number">
                <label> الاب</label>
                <h5 class="text-capitalize mb-75">
                  {{ data.father_name }}
                </h5>
              </b-form-group>
            </b-col>

            <b-col md="6" xl="3">
              <b-form-group label-for="number">
                <label> الام</label>
                <h5 class="text-capitalize mb-75">
                  {{ data.mother_name }}
                </h5>
              </b-form-group>
            </b-col>

            <b-col md="6" xl="3">
              <b-form-group label-for="number">
                <label> النسبة</label>
                <h5 class="text-capitalize mb-75">
                  {{ data.last_name }}
                </h5>
              </b-form-group>
            </b-col>

            <b-col md="6" xl="3" v-if="data.gender">
              <b-form-group>
                <label> الجنس</label>
                <h5 class="text-capitalize mb-75">
                  {{ data.gender.name }}
                </h5>
              </b-form-group>
            </b-col>
            <b-col md="6" xl="3" v-if="data.family_status_id">
              <b-form-group>
                <label> طبيعة العائلة </label>
                <h5 class="text-capitalize mb-75">
                  {{ data.family_status_id.name }}
                </h5>
              </b-form-group>
            </b-col>
            <b-col md="6" xl="3" v-if="data.displacement_date">
              <b-form-group>
                <label>  تاريخ النزوح </label>
                <h5 class="text-capitalize mb-75">
                  {{ data.displacement_date }}
                </h5>
              </b-form-group>
            </b-col>

            <b-col md="6" xl="3" v-if="data.current_work_id">
              <b-form-group label-for="current_work">
                <label> العمل الحالي</label>
                <h5 class="text-capitalize mb-75">
                  {{ data.current_work_id.name }}
                </h5>
              </b-form-group>
            </b-col>
            <b-col md="6" xl="3" v-if="data.last_work_id">
              <b-form-group label-for="last_work">
                <label> العمل السابق</label>
                <h5 class="text-capitalize mb-75">
                  {{ data.last_work_id.name }}
                </h5>
              </b-form-group>
            </b-col>
            <b-col md="6" xl="3" v-if="data.martial_status">
              <b-form-group label-for="martial_status">
                <label> الحالة الاجتماعية</label>

                <h5 class="text-capitalize mb-75">
                  {{ data.martial_status.name }}
                </h5>
              </b-form-group>
            </b-col>

            <b-col md="6" xl="3">
              <b-form-group label-for="birth_place">
                <label> مكان الولادة</label>
                <h5 class="text-capitalize mb-75">
                  {{ data.birth_place }}
                </h5>
              </b-form-group>
            </b-col>

            <b-col md="6" xl="3" v-if="data.educational_attainment_id">
              <b-form-group label-for="educational_attainment_id">
                <label> التحصيل العلمي </label>

                <h5 class="text-capitalize mb-75">
                  {{ data.educational_attainment_id.name }}
                </h5>
              </b-form-group>
            </b-col>
            <b-col md="6" xl="3" v-if="data.educational_status">
              <b-form-group label-for="educational_status">
                <label>  الحالة التعليمية </label>

                <h5 class="text-capitalize mb-75">
                  {{ data.educational_status.name }}
                </h5>
              </b-form-group>
            </b-col>
            <b-col md="6" xl="3" v-if="data.phone">
              <b-form-group label-for="number">
                <label> رقم الهاتف  </label>
                <h5 class="text-capitalize mb-75">
                  {{ data.phone }}
                </h5>
              </b-form-group>
            </b-col>


            </b-row>
          <b-row>
            <b-col md="6" xl="4">
              <b-form-group label-for="birth_date">
                <label> تاريخ الميلاد</label>
                <h5 class="text-capitalize mb-75">
                  {{ data.birth_date }}
                </h5>
                <!-- <b-form-input v-model="person.dateOfBirth" placeholder="" /> -->
              </b-form-group>
            </b-col>

          </b-row>
</div>



            </b-modal>
    <!-- Table session Card -->
    <b-card
      no-body
      class="mb-0"
    >
    
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <!-- <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label> -->
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <!-- <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              /> -->

            </div>
          </b-col>
        </b-row>
      </div>
<b-row class="mb-3 p-2">
  <b-col
            cols="6"
            md="6"
            
         
          >
            <label>
              نوع الحضور   
            </label>
                <v-select
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="optionType"
                  v-model="AttendeeType"
                    :reduce="(val) => val.value"
                   />
            
          </b-col>

        </b-row>
      <b-table
        ref="refsessionListTable"
        class="position-relative"
        :items="fetchsessionBenef"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

      <template #cell(attendee_id)="data">
 <span v-if="data.item.attendee_type === 'App\\Models\\Volunteer'"> {{data.item.attendee_id.name}}</span>
 <span v-else-if="data.item.attendee_type === 'App\\Models\\User'">  {{ `${data.item.attendee_id.first_name}  ${data.item.attendee_id.last_name}`}} </span>

<span v-else>  {{ `${data.item.attendee_id.first_name} ${data.item.attendee_id.father_name} ${data.item.attendee_id.last_name}`}}</span>

        </template>

        <template #cell(attendee_type)="data">
<span v-if="data.item.attendee_type === 'App\\Models\\Person'"> شخص مسجل</span>
<span v-else-if="data.item.attendee_type === 'App\\Models\\User'"> مستخدم </span>
<span v-else-if="data.item.attendee_type === 'App\\Models\\Volunteer'">  متطوع وصول غير مستخدم</span>
<span v-else> شخص غير مسجل</span>


        </template>
        <template #cell(id)="data">
 <span v-if="data.item.attendee_type === 'App\\Models\\Volunteer'"> {{data.item.attendee_id.number}}</span>
<span v-else>  {{ data.item.attendee_id.phone}}</span>

        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
            
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item  v-if="data.item.attendee_type !== 'App\\Models\\UnRegisteredPerson'" role='button' @click="showservice(data.item.id )">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">تحويل</span>
            </b-dropdown-item>

            <b-dropdown-item  role='button' @click="showAttend(data.item.attendee_id, data.item.attendee_type)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">عرض</span>
            </b-dropdown-item>

          

          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalFB"
              :per-page="perPage"
              :options="perPageOptions"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BModal,
  BDropdown,
  BDropdownItem,
  BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import store from '@/store'
import router from '@/router'
import sessionDetails from './session-Details.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import { codeMask } from "./code";
import { required, max } from '@validations'
import AttendeceList from './AttendeceList'
import sessionStoreModule from '../sessionStoreModule'

export default {
  data() {    return {
      newService: {},
      optionService: [],
      optionSubServices: [],
      data:{},
      type:'',
      optionType: 
      [{label:'شخص مسجل',value:'Person'}, 
      {label:' مستخدم',value:'User'}, 
      {label:'شخص غير مسجل',value:'UnRegisteredPerson'},
      {label: "متطوع وصول غير مسجل",value:'Volunteer'}
    ],
    }},
    mounted() {
   this.getServiceType()
  },

    methods:{
      getServiceType() {
      this.$http.get('/api/v1/beneficiaries_page/get_dropdown_data').then(res => {
        const proposal_services = res.data.data.main_services
        // console.log(proposal_services);
        proposal_services.forEach(el => {
          this.optionService.push({ name: el.name, id: el.id })
        })
      })
    },
      showservice(id) {
      this.$refs['my-modal'].show()
      this.newService.session_attende_id = id
    },
    showAttend(item, type) {
      this.$refs['my-modal-attend'].show()
      console.log(item)
      this.data = item
      this.type = type
    },
    getSubSevice(id) {
      this.optionSubServices = []

      const url = `/api/v1/main_services/${id}/proposal_services`
      this.$http.get(url).then(res => {
        const subServices = res.data.data
        subServices.forEach(el => {
          this.optionSubServices.push({ name: el.name, id: el.id })
        })
      })
    },
    addnewService() {
      return new Promise((resolve, reject) => {
        this.$refs.phonesimple.validate().then(success => {
          if (success) {
            const url = `/api/v1/mobile-team/session-refferal`
            this.$http.post(url, this.newService).then(res => {
      this.newService={}
              this.$swal({
            title: '',
            text: '  تم التحويل',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
            this.$refs['my-modal'].hide()
           
          }
            )}
        })
      })
    },
    },
  setup() {
    const session_APP_STORE_MODULE_NAME = 'app-session'

    // Register module
    if (!store.hasModule(session_APP_STORE_MODULE_NAME)) { store.registerModule(session_APP_STORE_MODULE_NAME, sessionStoreModule) }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(session_APP_STORE_MODULE_NAME)) { store.unregisterModule(session_APP_STORE_MODULE_NAME) }
    })

   
    const {
      tableColumns,
      perPage,
      currentPage,
      totalFB,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      AttendeeType,
      isSortDirDesc,
      refsessionListTable,
      refetchData,

      fetchsessionBenef,
      // Extra Filters

    } = AttendeceList()

    return {
      // Sidebar

      fetchsessionBenef,

      tableColumns,
      perPage,
      currentPage,
      totalFB,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      AttendeeType,
      isSortDirDesc,
      refsessionListTable,
      refetchData,

      // Filter
      avatarText,

    }
  },

  components: {
    BModal,
    BCard,
    BRow,
    required,
    ValidationProvider,
    ValidationObserver,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    sessionDetails,
    vSelect,
    BCardCode,
  },
}
</script>

<style scoped>
div {
  color: #7e7a9f;
  font-weight: bold;
  font-session: "Cairo", sans-serif;
}

</style>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
