<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-form>
        <b-card-code title=" معلومات  الجلسة ">
     
          <b-row>

            <b-col
              md="6"
              xl="3"
            >
              <b-form-group label-for="number">
                <label>  العنوان</label>
                <h5 class="text-capitalize mb-75">
                  {{ sessionDetails.subject }}
                </h5>

              </b-form-group>
            </b-col>

            <b-col
              md="6"
              xl="3"
            >
              <b-form-group label-for="number">
                <label>  النوع</label>
                <h5 class="text-capitalize mb-75">
                  {{ sessionDetails.type }}
                </h5>
              </b-form-group>
            </b-col>

            <b-col
              md="6"
              xl="3"
            >
              <b-form-group label-for="number">
                <label>  التصنيف</label>
                <h5 class="text-capitalize mb-75">
                  {{ sessionDetails.kind }}
                </h5>
              </b-form-group>
            </b-col>

            <b-col
              md="6"
              xl="3"
            >
              <b-form-group label-for="number">
                <label>    تاريخ</label>
                <h5 class="text-capitalize mb-75">
                  {{ sessionDetails.date }}
                </h5>
              </b-form-group>
            </b-col>

            <b-col
              md="6"
              xl="3"
            >
              <b-form-group label-for="note">
                <label> تقرير الجلسة</label>

                <h5 class="text-capitalize mb-75">
                  {{ sessionDetails.session_report }}
                </h5>
              </b-form-group>
            </b-col>

          </b-row>

        </b-card-code>
       
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BRow, BCol,
  BForm,

} from 'bootstrap-vue'



import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  components: {
    BForm,
    BCardCode,

    BRow,

    BCol,
  },
  //   props: {
  //     sessionDetails: {
  //       type: Object,
  //       default: () => {},
  //     },
  //   },
  data() {
    return {
      sessionDetails: {

    
      },
    
    }
  },
  created() {
    this.getsessionInfo()
  },
  methods: {
    getsessionInfo() {
      const url = `/api/v1/mobile-team/sessions/${this.$route.params.id}`

      this.$http.get(url).then(res => {
        // console.log(res)
        this.sessionDetails = res.data
     
      })
    },
  },
}
</script>

<style scoped>
label {
  color: #7e7a9f;
  font-weight: bold;
  font-size: 12px;
  font-session: "Cairo", sans-serif;
}
.btn-purple {
  color: white;
  background-color: #0971b8 !important;
}
.align-left {
  left: 50px;
}

.but {
  top: 40px;
}
</style>
