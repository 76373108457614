import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { title } from "@core/utils/filter";

// Notification
import Vue from "vue";
import router from '@/router'
export default function AttendeceList() {
  // Use toast



  // if (Designations) emit('addDesignation')
  const refsessionListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "attendee_id", label: "الاسم الكامل", sortable: true },
    {
      key: "id",
      label: " رقم الهاتف  ",
      
      sortable: true,
    },
    // {
    //   key: "attendee_id.last_name",
    //   label: "النسبة",
    //   formatter: title,
    //   sortable: true,
    // },
    
    {
      key: "attendee_type",
      label: " نوع الحضور ",
      
      sortable: true,
    },
   
    { key: "actions" ,label: "  الخيارات",},
  ];
  const perPage = ref(10);
  const totalFB = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const AttendeeType = ref('');
  const isSortDirDesc = ref(true);

  const dataMeta = computed(() => {
    const localItemsCount = refsessionListTable.value
      ? refsessionListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalFB.value,
    };
  });

  const refetchData = () => {
    refsessionListTable.value.refresh();
  };

  watch([currentPage, perPage, searchQuery, AttendeeType], () => {
    refetchData();
  });

 
  const fetchsessionBenef = (ctx, callback) => {
    console.log(AttendeeType.value)
    let data = {
      id:router.currentRoute.params.id,
      type:AttendeeType.value
    }
    store
      .dispatch("app-session/fetchsessionBenef",data
       
        )
      .then((response) => {
 //  console.log(response.data.attendance)
        const data= response.data.attendance
        
        callback(data)
        // totalFB.value = total

     
      })
      .catch(() => {
      //   Vue.swal({
      //         title: "حدثت مشكلة في استرجاع البيانات",
      // icon: "error",
           
      //   confirmButtonText: "موافق",
      //   customClass: {
      //     confirmButton: "btn btn-primary",
      //   },
      //   buttonsStyling: false,
      // });
      });
  };

  return {
    fetchsessionBenef,

    tableColumns,
    perPage,
    currentPage,

    totalFB,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    AttendeeType,
    isSortDirDesc,
    refsessionListTable,

    refetchData,
  };
}
